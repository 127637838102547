.containerLogo {
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00a69a;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.buttonDownload {
  height: 45px;
  width: max-content;
  padding: 5px 15px;
  background: #00a69a;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}
.buttonDownloadQrCode {
  height: 45px;
  width: 150px;
  padding: 5px 15px;
  background: #00a69a;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}
.imgLogo {
  width: 150px;
  height: 80px;
}
.headerRegisterAreaButton {
  font-size: 25px;
  text-decoration: none;
  margin-right: 10px;
  color: black;
  cursor: pointer;
}
.headerRegisterAreaTitle {
  font-size: 25px;
  font-weight: 500;
}
/* modal  */
.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}
.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}

/* css load ------------------------*/
.ldsroller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  scale: 0.3;
}
.ldsroller div {
  animation: ldsroller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.ldsroller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.ldsroller div:nth-child(1) {
  animation-delay: -0.036s;
}
.ldsroller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.ldsroller div:nth-child(2) {
  animation-delay: -0.072s;
}
.ldsroller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.ldsroller div:nth-child(3) {
  animation-delay: -0.108s;
}
.ldsroller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.ldsroller div:nth-child(4) {
  animation-delay: -0.144s;
}
.ldsroller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.ldsroller div:nth-child(5) {
  animation-delay: -0.18s;
}
.ldsroller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.ldsroller div:nth-child(6) {
  animation-delay: -0.216s;
}
.ldsroller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.ldsroller div:nth-child(7) {
  animation-delay: -0.252s;
}
.ldsroller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.ldsroller div:nth-child(8) {
  animation-delay: -0.288s;
}
.ldsroller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes ldsroller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
