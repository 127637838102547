.headerRegisterAreaButton {
  font-size: 25px;
  text-decoration: none;
  margin-right: 30px;
  color: black;
}
.headerRegisterAreaTitle {
  font-size: 25px;
  font-weight: 500;
}
.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}
.registrationInput {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInput input,
.registrationInput select {
  /*width: 100% !important;*/
  border-radius: 8px;
  /* border: 1px solid #dbdbdb; */
  border: none;
  /* flex: 1; */
  min-width: 45%;
  padding: 10px;
  margin: 10px;
}
/*
input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}*/
.dataTItle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #a8a8b3;
}
.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}
.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}
