* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	background-color: #f8f8fa;
}

.asideMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #00a69a;
	color: #ffffff;
	width: 250px;
	padding: 0 16px;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100vh;
	margin-right: 47px;
	position: fixed;
}
.logoAsideBar {
	margin-top: 20px;
	width: 200px;
	height: auto;
	margin-bottom: 8px;
}
.empresa {
	background: #1a877f;
	border-radius: 8px;
	width: 100%;
	text-align: start;
	margin: 0 15px;
	padding: 16px 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}
.empresa h2 {
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	margin: 2px 0;
}
.empresa p {
	font-weight: 500;
	font-size: 12px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.42);
	letter-spacing: -0.3px;
	margin: 0;
}
.asideMenuList {
	height: 100%;
	width: 100%;
	margin: 10px;
}
.subList {
	opacity: 0;
	visibility: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 0;
	display: block;
}
.subList a {
	opacity: 1;
	visibility: visible;
	height: 35px;
	width: 200px;
}
.subList li {
	border-radius: 8px;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.subList.show {
	opacity: 1;
	visibility: visible;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: start;
	height: 100%;
	min-height: 40px;
	transition: 0.3s;
}
.subList li:hover {
	background: #1a877f;
	color: #ffffff;
}
.subListItems {
	display: none;
	align-items: center;
	justify-content: flex-start;
	text-decoration: none;
	color: inherit;
}
.subList.show .subListItems {
	display: flex;
}
.dropDownMenu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	max-height: 250px;
	margin-top: 5px;
	transition: 0.3s;
}
.dropDownMenu > a {
	margin-bottom: 0;
	border-radius: 8px;
	padding-left: 8px;
	width: 100%;
	min-height: 35px;
	background: #0d968c;
}
.dropDownMenu.show {
	background: #0d968c;
	border-radius: 8px;
}
.menuList {
	padding: 0;
	text-align: start;
	height: 100%;
	max-height: 100vh;
}
.menuList .itemHeader {
	border-radius: 8px;
}

.footer {
	display: flex;
	flex-direction: column;
	background-color: inherit;
}
.containerFooterItem:hover {
	background-color: #1a877f !important;
	border-radius: 8px;
}
.itemFooter {
	margin-top: 10px;
}
.containerSubListItem {
	background-color: #0d968c !important;
	border-radius: 8px !important;
}
.containerSubListItem:hover {
	background: #1a877f !important;
}
.AsideMenuListDrop {
	min-height: 70%;
}
.MuiTypography-root {
	font-family: "Poppins", sans-serif !important;
	font-size: 14px !important;
}
.containerSublistDrop {
	margin-top: 12px !important;
}
.containerSublist {
	background-color: white;
	border-radius: 8px;
	background-color: #0d968c;
}

.containerSublist .focusPage {
	background-color: #1a877f !important;
}

.focusPage {
	background-color: #1a877f !important;
	border-radius: 8px !important;
}

.home.focusPage {
	background-color: #1a877f !important;
	border-radius: 8px !important;
}

