
.btn{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.tableGenerator, .tableGenerator th, .tableGenerator td {
    background: #f8f8fade;
    font-weight: bold;
    border-radius: 0;
}