.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}

.checkboxContainerPropriedade {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.checkboxContainerPropriedade:hover {
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}

.labelTitle {
  font-size: 18px;
  color: #00756d;
}

.checkboxContainer .inputCheckbox {
  border-radius: 8px;
  height: 20px;
  padding: 0;
}

.inputCheckbox {
  accent-color: #00a69a;
  min-width: 20px !important;
}

.CheckPropriedadeLabel {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.CheckPropriedadeLabel:hover {
  cursor: pointer;
}

.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}

.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}

.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}

.errControl input {
  border: 1px solid red;
}

.errText {
  margin-bottom: -15px;
  color: red;
}
.testInput {
  width: 100%;
}
