.headerRegisterAreaButton {
  font-size: 25px;
  text-decoration: none;
  margin-right: 10px;
  color: black;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonConfig {
  width: 160px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.buttonConfig:hover {
  color: white;
  background: #00ba9d;
}

.buttonRemovePhotoConfig {
  width: 160px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-weight: 600;
}

.buttonRemovePhotoConfig:hover {
  background: gray;
  color: white;
}

.comentarioList {
  display: flex;
  flex-direction: column;
}

.selectCanvas {
  width: 100%;
  height: 45px;
  border-radius: 8px;
}

.tab {
  transition: 0.5s;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: auto !important;

  line-height: 140% !important;
  color: #2f2f2f;
}

.tab {
  transition: 0.5s;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: auto !important;

  line-height: 140% !important;
  color: #2f2f2f;
}

.focuzVertical {
  transition: 0.5s;
  /* border-radius: 8px 8px 0 0 !important; */
  background: #00a69a !important;
  color: #fff !important;
}

.focuzHorizontal {
  transition: 0.5s;
  border-radius: 8px !important;
  background: #00a69a !important;
  color: #fff !important;
}

.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}

.registrationInputModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
  flex: 1;
  min-width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px;
}

input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}
.input {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  margin-bottom: 10px;
  padding-left: 30px !important;
  padding-right: 40px;
}
.input:hover {
  border: 2px solid #00a69a;
}
.input:focus {
  outline: none;
  border: 2px solid #00a69a;
}
.iconPassword {
  color: #888888;
  position: absolute;
  margin-left: -40px;
  margin-top: -16px;
  cursor: pointer;
}
.icon {
  color: #888888;
  position: absolute;
  padding-left: 5px;
  padding-bottom: 7px;
  cursor: text;
}
.formInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
