.Select {
    height: 35px;
    min-width: 95px;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.textButtonOff {
    display: none;
    width: 100px;
}

.textButton {
    display: flex;
    min-width: 90px;
    height: 35px;
    text-align: center;
    cursor: pointer;
}