.Chart {
    width: 100%;
    height: 100%;
}

.ChartTitle {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
}

.Select {
    height: 35px;
    min-width: 95px;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.textButtonOff {
    display: none;
    width: 100px;
}

.textButton {
    display: flex;
    min-width: 90px;
    height: 35px;
    text-align: center;
    cursor: pointer;
}

.ButtonExportChart {
    background: #1A877F !important;
    border: 2px solid #1A877F;
    border-radius: 10px;
    color: #FFFFFF !important;
    width: 200px;
    height: 35px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
}