.tabs {
	width: 100%;
}

.tab {
	transition: 0.3s;
	font-family: "Poppins" !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: auto !important;

	line-height: 140% !important;
	color: #2f2f2f;
}

.focuz {
	transition: 0.3s;
	border-radius: 8px 8px 0 0 !important;
	background: #00a69a !important;
	color: #fff !important;
}

.tabPanel {
	width: 100%;
}
