.Select {
    height: 35px;
    min-width: 90px;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    margin-top: 0;
}

.textButtonOff {
    display: none;
    width: 100px;
}

.textButton {
    margin: 5px;
    margin-top: 0;
    display: flex;
    min-width: 90px;
    height: 35px;
    text-align: center;
    cursor: pointer;
}