
.tableModal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.trModal .thModal{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.thModal{
    flex:1;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}
.trModal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.tdModal{
    flex: 1;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #E6E7E9;
    text-align: center;
    font-size:18px;
}

.trModal:hover>.tdModal {
    background: rgba(0, 166, 154, 0.04);
    color: rgb(0, 0, 0);
    border: none;
}

.btn{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}


.tableModal, .tableModal .thModal, .tableModal .tdModal, .tablePagination {
    background: #f8f8fade;
    font-weight: bold;
    border-radius: 0;
}.labelButton {
	display: flex;
	align-items: center;
	padding: 8px;
	border: none;
	border-radius: 4px;
	color: white;
	background-color: #00a69a;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 14px;
	width: 150px;
    text-decoration: none;
}
.labelButton:hover {
	background-color: #038d84;
	cursor: pointer;
}