.containerSubItem {
	border-radius: 8px !important;
	height: 35px !important;
	margin-bottom: 5px !important;
	background: #1a877f !important;
}

.containerSubItem:hover {
	/* opacity: 80% !important; */
	background: #187a74 !important;
}

.containerSubItemDropDown {
	border-radius: 8px !important;
	height: 35px !important;
	margin-bottom: 5px !important;
	background: #1a877f !important;
	border: 1px solid #146964 !important;
}

.containerSubItemDropDown:hover {
	/* opacity: 80% !important; */
	background: #187a74 !important;
}

.containerSubSubItem {
	border-radius: 8px;
	margin-bottom: 5px !important;
	text-align: center;
	background: #1a877f;
}

.containerSubSubItemDropDown {
	border-radius: 8px !important;
	margin-top: 3px !important;
	margin-bottom: 3px !important;
	height: 35px !important;
	background: #187a74 !important;
	border: 1px solid #ffffff !important;
}

.containerSubSubItemDropDown:hover {
	/* opacity: 80% !important; */
	background: #146964 !important;
}

.registrationInputModal {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInput {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInput input, .registrationInput select {
	width: 100% !important;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	flex: 1;
	min-width: 45%;
	padding: 10px;
	margin: 10px;
}

.desativado {
	color: rgb(140, 140, 140);
}

input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}

.registrationButtonCancelar {
	background-color: rgb(255, 255, 255);
	height: 45px;
	border-radius: 8px;
	border: 1px solid #e6e7e9;
	margin-right: 5px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #565758;
	padding: 10px;
	min-width: 110px;
	width: 100%;
}

.registrationButtoncadastrar {
	background-color: #1a877f;
	color: white;
	height: 45px;
	border-radius: 8px;
	width: 100%;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 10px;
	min-width: 110px;
}

.itemListWithSubList {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: start;
	width: 100%;
	border-radius: 8px !important;
	height: 35px !important;
	margin-bottom: 5px !important;
	background: #1a877f !important;
}

.itemListWithSubList p {
	margin-right: 10px;
}