.selectInputPlaceholder {
  color: #888888;
}

.containerImgSafra {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.imgContent {
  width: 100%;
  border-radius: 8px;
}

.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}

.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}

.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  text-align: end;
}

.errControl select,
.errControl input {
  border: 1px solid red !important;
}

.errText {
  margin-bottom: -15px;
  color: red;
}

.map {
  /* controla o tamanho do mapa ---------------------!!!!!!!!!!!!!!!!!!!*/
  width: 100%;
  height: 400px;
  position: relative;
}
.mapBtn {
  bottom: 10px;
  left: 25px;
  position: absolute;
  width: 255px;
  height: max-content;
  border-radius: 8px;
  z-index: 1;
  font-weight: bold;
  font-size: 16px;
  text-decoration: none;
  color: #000;
  background-color: rgba(255, 255, 255, 0.5);
}
.red {
  display: flex;
  margin: 5px;
  justify-content: start;
  align-items: center;
  height: 20px;
  width: 245px;
  border-radius: 8px;
  /* background-color: rgba(255, 0, 0, 0.3); */
}
.yellow {
  display: flex;
  margin: 5px;
  justify-content: start;
  align-items: center;
  height: 20px;
  width: 245px;
  border-radius: 8px;
  /* background-color: rgba(255, 255, 0, 0.3); */
}
.green {
  display: flex;
  margin: 5px;
  justify-content: start;
  align-items: center;
  height: 20px;
  width: 245px;
  border-radius: 8px;
  /* background-color: rgba(0, 166, 154, 0.3); */
}
.red:hover {
  background-color: rgba(255, 0, 0, 0.3);
}
.yellow:hover {
  background-color: rgba(255, 255, 0, 0.3);
}
.green:hover {
  background-color: rgba(0, 166, 154, 0.3);
}
.inputSafra {
  width: 100%;
}
