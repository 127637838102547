/* .headerSearch{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    
} */

.headerSearchButton a{
    font-size: 14px;
}
.headerSearchInput{
    max-width: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #E6E7E9;
}
.headerSearchInput a {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0px 8px 8px 0px;
}
.inputSearchContainer{
    width: 200%;
    height: 100%;
}
.buttonSearchContainer{
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} 
