.statusAtiva {
	font-family: "Roboto";
	background: rgb(163, 166, 0);
	font-style: normal;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 600;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

.statusFinalizada {
	font-family: "Roboto";
	background: #00a69a;
	border-radius: 5px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}

.btnSafra {
	width: 15%;
	min-width: 200px;
	text-align: center;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 10px 15px;
	font-family: "Poppins", sans-serif !important;
	font-weight: 400;
	font-size: 14px;
}

.tableGenerator,
.tableGenerator th,
.tableGenerator td {
	background: #f8f8fade;
	font-weight: bold;
	border-radius: 0;
}

.btn {
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
}

.modalContentTitle {
	margin-left: 30px;
}

.modalContenteError {
	margin-left: 20px;
	margin-bottom: -10px;
	color: rgba(255, 0, 0, 0.767);
	font-size: 16px !important;
}


.registrationInput {
	display: flex;
	flex-wrap: wrap;
	width: 90%;
}

.registrationInput input, .registrationInput .input__data,
.registrationInput select {
	width: 100% !important;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	flex: 1;
	min-width: 45%;
	padding: 10px;
	margin: 10px;
}

input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}

.registrationInputRed {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInputRed input {
	width: 100% !important;
	border-radius: 8px;
	border: 1px solid #ff0000;
	flex: 1;
	min-width: 45%;
	padding: 10px;
	margin: 10px;
	box-shadow: 0px 0px 5px 1px #a60000;
	outline: 2px solid #a60000;
}


.registrationButtonCancelar {
	background-color: rgb(255, 255, 255);
	height: 45px;
	border-radius: 8px;
	border: 1px solid #e6e7e9;
	margin-right: 5px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #565758;
	padding: 10px;
	min-width: 110px;
	width: 100%;
}

.registrationButtoncadastrar {
	background-color: #1a877f;
	color: white;
	height: 45px;
	border-radius: 8px;
	width: 100%;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 10px;
	min-width: 110px;
}

.tab {
	transition: 0.3s;
	font-family: 'Poppins' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: auto !important;

	line-height: 140% !important;
	color: #2F2F2F;
}

.focuz {
	transition: 0.3s;
	border-radius: 8px 8px 0 0 !important;
	background: #00A69A !important;
	color: #fff !important;

}

.tabPanel {
	width: 100%;
}