.selectInputPlaceholder {
  color: #888888 !important;
}

.registrationInputModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.registrationInputModal input,
.registrationInputModal select {
  flex: 1;
  min-width: 45%;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px;
}
input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}

.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}
.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}
.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.itemNameCuture {
  display: flex;
  border-radius: 40px;
  background: #34a59c;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 16px;
  padding: 5px 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
}
.itemButtonCuture {
  background-color: #34a59c;
  color: white;
  font-weight: 700;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}
.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}
.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.errControl input,
.errControl select {
  border: 1px solid red !important;
}
.errText {
  margin-bottom: -15px;
  color: red;
}
.inputOccurrencesCad {
  width: 100%;
}
