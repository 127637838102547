/* .statusConfimardo {
	text-align: center;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100px;
	height: 30px;
	background-color: #00a642;
	color: white;
	font-family: "Roboto";
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
}

.statusRecebido {
	text-align: center;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100px;
	height: 30px;
	background-color: #037ec2;
	color: white;
	font-family: "Roboto";
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
}

.statusColhido {
	text-align: center;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100px;
	height: 30px;
	background-color: #e8e034;
	color: white;
	font-family: "Roboto";
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
}

.statusRepassado {
	text-align: center;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100px;
	height: 30px;
	background-color: #feaa2c;
	color: white;
	font-family: "Roboto";
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
}

.statusRecusado {
	text-align: center;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100px;
	height: 30px;
	background-color: #f36464;
	color: white;
	font-family: "Roboto";
	font-style: normal;
	display: flex;
	align-items: center;
	justify-content: center;
} */

.tableGenerator,
.tableGenerator th,
.tableGenerator td {
  background: #f8f8fade;
  font-weight: bold;
  border-radius: 0;
}
.lineRequest:hover > td {
  background: rgba(0, 166, 154, 0.04);
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.labelButton {
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #00a69a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
}
.labelButton:hover {
  background-color: #038d84;
  cursor: pointer;
}

.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}

.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}

.modalContentTitle {
  margin-left: 30px;
}

.modalContentError {
  color: red;
  margin-left: 30px;
}

.selectInput:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}
.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}
.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}
.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}
.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}
.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}

.noErrControl {
  width: 100% !important;
  text-align: end;
  margin-right: 15px;
  display: flex;
}

.inputRequest {
  width: 100%;
}

.requestFilter input, select {
  /*width: 100% !important;*/
  border-radius: 8px;
  /* border: 1px solid #dbdbdb; */
  border: none;
  /* flex: 1; */
  min-width: 45%;
  padding-left: 5px;
  height: 35px;
  margin: 5px 2px 5px 2px;
}

.requestFilter input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}

.requestFilter {
  padding-bottom: 10px;
}