.menuItemReport {
	width: auto;
	text-align: left !important;
}
.menuItemReport:hover {
	background: rgb(255, 255, 255);
}
.ReportButton {
	background: #00a69a !important;
	border-radius: 4px;
	color: white !important;
	width: 100%;
}

.tabPanel {
	width: 100%;
}
