.registrationInputModal {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.registrationInputModal select {
    flex: 1;
    min-width: 45%;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    padding: 10px;
}

.registrationInputModal input {
    min-width: 0 !important;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    padding: 10px;
}

.registrationInputModalFav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0 !important;
}

.registrationInputModalFav input {
    min-width: 0 !important;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    padding: 10px;
}

input:focus {
    box-shadow: 0px 0px 5px 1px #00a69a;
    outline: 2px solid #00a69a;
}

.registrationButtoncadastrar {
    background-color: #1A877F;
    color: white;
    height: 45px;
    border-radius: 8px;
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
}

.noErrControl {
    width: 100% !important;
    text-align: end;
    margin-right: 15px;
    display: flex;
}

.inputRequestItem {
    width: 100%;
}

.ButtonCancelarModal {
    background-color: rgb(255, 255, 255);
    width: 150px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #e6e7e9;
    font-family: "Poppins", sans-serif;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #565758;
    padding: 8px;
}

.ButtonCancelarModal:hover {
    background-color: #6b6d6d;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.ButtonConfimModal {
    background-color: #038d84;
    color: white;
    width: 150px;
    height: auto;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 8px;
}

.ButtonConfimModal:hover {
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}

.ButtonConfirm {
    background-color: #038d84;
    color: white;
    width: max-content;
    min-height: 40px;
    height: auto;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 0 8px;
    margin-left: 10px;
}

.ButtonConfirm:hover {
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}