@import "~react-image-gallery/styles/css/image-gallery.css";

.img {
  width: auto;
  height: 130px;
}

.map {
  /* order: 1;*/
  height: 100vh;
  width: 100%;
}
.popover {
  background: white;
}
.imgDidYouKnow {
  width: auto;
  height: 100px;
}
.image-gallery {
  width: 100% !important;
}
.imgContainer {
  display: flex;
  place-items: center;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 450px;
  border: 1px solid;
}

.imgProdutor {
  display: flex;
  place-items: center;
  align-items: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  max-height: 600px;
  /*min-height: 450px;
  object-fit: cover;*/
  -webkit-box-shadow: 0px 43px 61px -57px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 43px 61px -57px rgba(0, 0, 0, 1);
  box-shadow: 0px 43px 61px -57px rgba(0, 0, 0, 1);
}

.containerDescricao {
  margin-top: 25px;
  font-size: 16px;
  color: rgb(49, 49, 49);
}

.buttonAboutOrigin {
  cursor: pointer;
  width: 80%;
  height: 45px;
  color: white;
  font-size: 20px;
  margin-top: 20px;
  background-color: #009199;
  text-decoration: none;
  border: none;
}

.imgCompanyPresentation {
  width: auto;
  height: 100%;
}

.titleTraceabilityProdut {
  font-size: clamp(2em, 1.25em + 3vw, 3em) !important;
}

.image-gallery-content {
  height: 400px !important;
}

.linkDidYouKnow {
  font-size: clamp(0.75rem, 1vw, 1.25rem) !important;
}

.titleProductPath {
  font-size: clamp(2em, 3vw, 4em) !important;
}

.input {
  width: 45vw;
  margin: 15px;
  height: 35px;
  border-radius: 8px;
  border: none;
  padding-left: 20px;
}

.button {
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: none;
  color: white;
  background-color: #009199;
  cursor: pointer;
}
.button:hover {
  background-color: #006b71;
}
.inputFistPage {
  height: 40px;
  width: 50vw;
  border-radius: 8px 0 0 8px;
  border: none;
  padding-left: 5px;
}
.buttonFistPage {
  font-weight: bold;
  width: 130px;
  background-color: white;
  color: black;
  height: 40px;
  border-radius: 0 8px 8px 0;
  padding: 0 5px;
  border: none;
  border-left: 1px solid black;
  cursor: pointer;
  transition: all 0.2s;
}
.buttonFistPage:hover {
  background-color: #009199;
  color: white;
}
