.tabs {
  width: 100%;
  color: #2f2f2f !important;
}

.tab {
  transition: 0.3s;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: auto !important;
  line-height: 140% !important;
  color: #2f2f2f;
}
.tabDesabilitado {
  transition: 0.3s;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: auto !important;
  line-height: 140% !important;
  color: #bcbcbc !important;
}

.focuz {
  transition: 0.3s;
  border-radius: 8px 8px 0 0 !important;
  background: #00a69a !important;
  color: #fff !important;
}

.tabPanel {
  width: 100%;
}

.boxTabsContainer {
  background: #ffffff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
}

.buttonTableUpStock {
  width: 20%;
  padding: 5px;
  margin: 5px;
  justify-content: space-evenly;
}

.tableGenerator,
.tableGenerator th,
.tableGenerator td {
  background: #f8f8fade;
  font-weight: bold;
  border-radius: 0;
}

.lineRequest:hover > td {
  background: rgba(0, 166, 154, 0.04);
  color: rgb(0, 0, 0);
  border: none;
  cursor: pointer;
}

.btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.registrationInputModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
  flex: 1;
  min-width: 45%;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px;
}

input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}

.labelButton {
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #00a69a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
}

.labelButton:hover {
  background-color: #038d84;
  cursor: pointer;
}

.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}

.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}

.modalContentTitle {
  margin-left: 30px;
}

.modalContentError {
  color: red;
  margin-left: 30px;
}

.selectInput:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}

.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}
