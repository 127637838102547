.registrationInputModal {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.registrationInputModal input,.registrationInputModal select {
	flex: 1;
	min-width: 45%;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	padding: 10px;
}
input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}

.registrationButtonCancelar {
	background-color: rgb(255, 255, 255);
	height: 45px;
	border-radius: 8px;
	border: 1px solid #e6e7e9;
	margin-right: 5px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #565758;
	padding: 10px;
	min-width: 110px;
	width: 100%;
}

.registrationButtoncadastrar {
	background-color: #1a877f;
	color: white;
	height: 45px;
	border-radius: 8px;
	width: 100%;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 10px;
	min-width: 110px;
}

.selectInputPlaceholder {
	color: #888888 !important;
    width: 100%;
}
.selectInputPlace{
    width: 100%;
}
.registrationTitle{
    display: flex;
    color: #5B5B5D;
    font-size: 20px;
    padding: 10px 10px;
    font-weight:  500;  
}