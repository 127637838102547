.tableGenerator,
.tableGenerator th,
.tableGenerator td {
  background: #f8f8fade;
  font-weight: bold;
  border-radius: 0;
}
.harvestButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: #00a69a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.harvestButton:hover {
  background-color: #038d84;
  cursor: pointer;
}
.modalContent {
  color: #5b5b5d;
  font-size: 20px;
  font-weight: 500;
}
.registrationInput {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInput input,
.registrationInput select {
  width: 100% !important;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  flex: 1;
  min-width: 45%;
}
input:focus {
  box-shadow: 0px 0px 5px 1px #00a69a;
  outline: 2px solid #00a69a;
}
.cultureNameModal {
  font-size: 16px;
  display: flex;
  margin-top: 25px;
}
.cultureNameModal p {
  margin-right: 15px;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}
.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}

.itemNameCuture {
  display: flex;
  border-radius: 40px;
  background: #34a59c;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 16px;
  padding: 5px 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
}
.itemButtonCuture {
  background-color: #34a59c;
  color: white;
  font-weight: 700;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}
.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}
.modalContenteError {
  margin-left: 20px;
  margin-bottom: -10px;
  color: rgba(255, 0, 0, 0.767);
  font-size: 16px !important;
}
.registrationInputRed {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInputRed input {
  width: 100% !important;
  border-radius: 8px;
  border: 1px solid #ff0000;
  flex: 1;
  min-width: 45%;
  padding: 10px;
  margin: 10px;
  box-shadow: 0px 0px 5px 1px #a60000;
  outline: 2px solid #a60000;
}
.inputDataDemanda {
  width: 100%;
  padding-left: 10px;
}
