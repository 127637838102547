.Select {
    height: 35px;
    min-width: 90px;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
}

.ButtonOff {
    visibility: hidden !important;
    width: 100px;
}

.Button {
    visibility: visible !important;
    min-width: 90px;
    height: 35px;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.textButtonOff {
    display: none;
    width: 100px;
}

.textButton {
    display: flex;
    min-width: 90px;
    height: 35px;
    text-align: center;
    cursor: pointer;
}

.ButtonExportChartDisable {
    background: #ffffff !important;
    border: 2px solid #000000;
    border-radius: 10px;
    color: #000000 !important;
    width: 200px;
    height: 35px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
}

.ButtonExportChart {
    background: #1A877F !important;
    border: 2px solid #1A877F;
    border-radius: 10px;
    color: #FFFFFF !important;
    width: 200px;
    height: 35px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
}

.ButtonExportChart:hover {
    cursor: pointer;
    background-color: #1a8780d5 !important;
}

.ButtonCancelarModal {
    background-color: rgb(255, 255, 255);
    width: 150px;
    height: 45px;
    border-radius: 8px;
    border: 1px solid #E6E7E9;
    font-family: "Poppins", sans-serif;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #565758;
    padding: 8px;
}

.ButtonCancelarModal:hover {
    background-color: #6b6d6d;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.ButtonColherModal {
    background-color: #038d84;
    color: white;
    width: 150px;
    height: 45px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 8px;
}

.ButtonColherModal:hover {
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}

.ButtonCancelar {
    background-color: rgb(255, 255, 255);
    width: 100%;
    min-width: max-content;
    min-height: 25px;
    height: max-content;
    border-radius: 8px;
    border: 1px solid #E6E7E9;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #565758;
    padding: 5px;
}


.ButtonColher {
    background-color: #038d84;
    color: white;
    width: 100%;
    min-width: max-content;
    min-height: 25px;
    height: max-content;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 5px;
}

.ButtonColher:hover {
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}

.titleTabelaModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.noErrControl {
    width: 100% !important;
    text-align: end;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
}

.SelectSafra {
    height: 35px;
    width: 100%;
    border: none;
    background: rgba(86, 87, 88, 0.12);
    border-radius: 4px;
    cursor: pointer;
    text-align: start;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: start;
}

.labelTitle {
    font-size: 18px;
    color: #000000;
}

.checkboxContainer .inputCheckbox {
    border-radius: 8px;
    height: 20px;
    padding: 0;
}

.inputCheckbox {
    accent-color: #00a69a;
    min-width: 20px !important;
}

.textDropdown {
    text-decoration: none !important;
    color: black;
}

@media only screen and (max-width: 400px) {

    .labelTitle {
        font-size: 1.4vmax;
        color: #000000;
    }

}