.App {
  font-family: "Poppins" !important;
}

.react-datepicker-popper {
  z-index: 1400 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}