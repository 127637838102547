.tab {
  transition: 0.3s;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: auto !important;
  /* width: max-content !important; */
  line-height: 140% !important;
  color: #2f2f2f;
  padding: 0 10px !important;
}
.focuz {
  transition: 0.3s;
  border-radius: 8px 8px 0 0 !important;
  background: #00a69a !important;
  color: #fff !important;
  /* width: max-content !important; */
}
.tabPanel {
  width: 100%;
}
.btnGoto {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  background: #fff !important;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  border-radius: 4px;
  /* background: #00a69a !important; */
  background-color: rgba(150, 150, 150, 0.1);
}
.btnContainerInputDate {
  height: 35px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 1);
}
.btnGoToPdf {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-right: 15px;
  height: 35px;
  min-width: max-content;
  width: 100%;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  background: #00a69a !important;
  /* background-color: rgba(250, 250, 250, 1); */
}
