.selectInputPlaceholder {
  color: #888888 !important;
}
.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}
.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.errControl select,
.errControl input {
  border: 1px solid red;
}
.errText {
  margin-bottom: -15px;
  color: red;
}
.inputEmbalagem {
  width: 100%;
}
