.empresa {
	background: #1a877f;
    border: 1px solid transparent;
	border-radius: 8px ; 
	width: auto;
	text-align: start;
	padding: 16px 12px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
    color: white;
    cursor: pointer;
}
.embresa:hover{
	background: #1a877f !important;
}
.empresaTitle{
	font-weight: 700;
	font-size: 12px;
	line-height: 140%;
	margin: 2px 0;
}
.empresaCnpj{
	font-weight: 500;
	font-size: 10px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.42);
	letter-spacing: -0.3px;
	margin: 0;
}
.css-114o79x-MuiButtonBase-root-MuiButton-root{
    text-transform: none !important;
    background-color: #1a877f !important;
}
.listproperty:hover{
	border-radius: 4px;
	background: #1a878056;
}