.logo {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
}

.loginTextContainer h2 {
    font-size: 34px;
    font-weight: 700;
    color: #1A8B82;
}

.loginTextContainer p {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    color: #1A8B82;
}

.formInput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input {
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 2px solid #DBDBDB;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
}

.input:hover {
    border: 2px solid #00A69A;
}

.input:focus {
    outline: none;
    border: 2px solid #00A69A;
}

.buttonLogin {
    width: 100%;
    height: 45px;
    background: #18827b;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
}

.buttonLogin:hover {
    background: #16706a;
    cursor: pointer;
}

.link {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #1A877F;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.mensagemError {
    color: red;
    font-size: 14px;
    font-weight: 400;
}

.mensagemSuccess {
    color: green;
    font-size: 14px;
    font-weight: 400;
}

.ldsroller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    scale: 0.2;
}

.ldsroller div {
    animation: ldsroller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.ldsroller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(0, 0, 0);
    margin: -4px 0 0 -4px;
}

.ldsroller div:nth-child(1) {
    animation-delay: -0.036s;
}

.ldsroller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.ldsroller div:nth-child(2) {
    animation-delay: -0.072s;
}

.ldsroller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.ldsroller div:nth-child(3) {
    animation-delay: -0.108s;
}

.ldsroller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.ldsroller div:nth-child(4) {
    animation-delay: -0.144s;
}

.ldsroller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.ldsroller div:nth-child(5) {
    animation-delay: -0.18s;
}

.ldsroller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.ldsroller div:nth-child(6) {
    animation-delay: -0.216s;
}

.ldsroller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.ldsroller div:nth-child(7) {
    animation-delay: -0.252s;
}

.ldsroller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.ldsroller div:nth-child(8) {
    animation-delay: -0.288s;
}

.ldsroller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

.buttonLoginCarregando {
    width: 100%;
    height: 45px;
    background: #5bb1ab;
    border-radius: 4px;
    border: 1px solid #000;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@keyframes ldsroller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}