.MuiDropzonePreviewList-imageContainer {
	margin: 0 20px !important;
	padding: 0 !important;
	width: 100% !important;
}
.MuiDropzonePreviewList-image {
	margin: 0 !important;
	padding: 0 !important;
	width: Auto !important;
	border-radius: 8px !important;
}
.MuiDropzonePreviewList-root {
	margin: 0 !important;
	margin-top: 15px !important;
	padding: 0 !important;
	width: 40% !important;
	flex-wrap: nowrap !important;
	align-items: center !important;
	justify-content: flex-start !important;
}
.MuiDropzonePreviewList-removeButton {
	margin: 0 !important;
	padding: 0 !important;
	background: #f36464 !important;
	color: white !important;
}
.MuiDropzoneArea-textContainer {
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	align-items: center;
}
.MuiDropzoneArea-root {
	display: flex;
	border: 5px dashed #1a877f !important;

	border-radius: 10px !important;
	justify-content: center;
	align-items: center;
}
.MuiDropzoneArea-icon {
	height: 70px !important;
	width: 90px !important;
	border-radius: 0px;
	color: #00a69a !important;
}
.MuiDropzoneArea-text {
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 24px !important;
	line-height: 28px !important;
	color: #d1d4da;
}
