.selectInputPlaceholder {
  color: #888888;
}

.labelColorText {
  color: white;
  text-align: center;
}
.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}
.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  text-align: end;
}
.errControl select,
.errControl input {
  border: 1px solid red !important;
}
.errText {
  margin-bottom: -15px;
  color: red;
}
.inputText {
  width: 100% !important;
}
