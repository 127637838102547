.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}
.selectInputPlaceholder {
  color: #888888;
}
.checkboxContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}
.inputCheckbox {
  accent-color: #00a69a;
  min-width: 20px !important;
}
.labelTitle {
  font-size: 18px;
  color: #00756d;
}
.labelTitleFrase {
  font-size: 18px;
  color: black;
}
.checkboxContainer .inputCheckbox {
  border-radius: 8px;
  height: 20px;
  padding: 0;
}
.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.noErrControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}
.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
}
.errControl select,
.errControl input {
  border: 1px solid red !important;
}
.errText {
  margin-bottom: -15px;
  color: red;
}
.testInput {
  width: 100%;
}
.checkboxContainer .inputCheckbox {
  border-radius: 8px;
  height: 20px;
  padding: 0;
}

.inputCheckbox {
  accent-color: #00a69a;
  min-width: 20px !important;
}