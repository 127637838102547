.table-container {
  flex: 1;
  display: flex;
  align-self: center;
  justify-content: center;
  margin: 20px;
  border-radius: 8px;
}

.table {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid #e6e7e9;
  border-radius: 8px;
}

thead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

tr th {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

th {
  flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

tbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

td {
  flex: 1;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #e6e7e9;
  text-align: center;
  font-size: 18px;
}

tr:hover > td {
  background: rgba(0, 166, 154, 0.04);
  color: rgb(0, 0, 0);
  border: none;
}

tr:hover > td td {
  background: rgba(0, 166, 154, 0);
  color: rgb(0, 0, 0);
  border: none;
}

.btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.check tr {
  background: rgba(255, 0, 0, 0.04);
}

tr:focus > td {
  background: rgba(0, 166, 154, 0.04);
}

tr:focus > td td {
  background: rgba(0, 166, 154, 0);
}

.verde {
  background: green;
  color: white;
}

.tableGenerator,
.tableGenerator th,
.tableGenerator td {
  background: #f8f8fade;
  font-weight: bold;
  border-radius: 0;
}

.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 50%;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  min-width: 100px;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 100%;
  min-width: 100px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}
