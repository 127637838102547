.map {
	/* controla o tamanho do mapa ---------------------!!!!!!!!!!!!!!!!!!!*/
	width: 100%;
	height: 400px;
	position: relative;

}

.mapBtn {
	bottom: 10px;
	left: 45px;
	position: absolute;
	width: 100px;
	height: 40px;
	border: 1px solid #1a877f;
	border-radius: 8px;
	z-index: 1;
	cursor: pointer;
	background: white;
	font-weight: bold;
	font-size: 16px;
	text-decoration: underline;
	color: #000;

}

.registrationInput {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.mapBtn:hover {
	background: #00a69a;
	color: white;
}

.propriedadeButtonSalvarBtn {
	padding: 8px 15px;
	background: #1a877f;
	border-radius: 8px;
	border: none;
	color: white;
	cursor: pointer;
}

.titlePropertyContentLeft {
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	text-align: left;
}

.titlePropertyContentLeft h2 {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.5px;
	color: rgba(24, 24, 25, 0.9);
}

.titlePropertyContentLeft p {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	color: #2f2f2f;
}

.btn {
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
}

.propertyContentList {
	width: 100%;
	max-height: 70vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.propertyContentItemList {
	list-style: none;
	width: 100%;
	border-radius: 8px;
	padding: 5px !important;
	margin: 10px 5px;
}

.registrationTitle {
	display: flex;
	color: #5b5b5d;
	font-size: 20px;
	padding: 10px 10px;
	font-weight: 500;
}

.registrationInputModal {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
	flex: 1;
	min-width: 45%;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	padding: 10px;
}

input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}

.registrationButtonCancelar {
	background-color: rgb(255, 255, 255);
	height: 45px;
	border-radius: 8px;
	border: 1px solid #e6e7e9;
	margin-right: 5px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #565758;
	padding: 10px;
	min-width: 110px;
	width: 100%;
}

.registrationButtoncadastrar {
	background-color: #1a877f;
	color: white;
	height: 45px;
	border-radius: 8px;
	width: 100%;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 10px;
	min-width: 110px;
}

.propertyVerde {
	background: #00a69a;
}

.ButtonCancelarModal {
	background-color: rgb(255, 255, 255);
	width: 50%;
	height: 45px;
	border-radius: 8px;
	border: 1px solid #E6E7E9;
	font-family: "Poppins", sans-serif;
	margin-right: 5px;
	cursor: pointer;
	min-width: 100px;
	font-weight: 500;
	font-size: 16px;
	color: #565758;
	padding: 8px;
}

.ButtonCancelarModal:hover {
	background-color: #6b6d6d;
	color: white;
	cursor: pointer;
	transition: 0.3s;
}

.ButtonColherModal {
	background-color: #038d84;
	color: white;
	width: 50%;
	min-width: 100px;
	height: 45px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	margin-right: 5px;
	font-weight: 500;
	font-size: 16px;
	padding: 8px;
}

.ButtonColherModal:hover {
	transition: 0.3s;
	background: #156661;
	cursor: pointer;
}

.dialogActionsButtons {
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
}

.buttonTypeMapActive{
	background: #00a69a;
	border-radius: 4px;
	text-decoration: none;
	color: white;
	padding: 5px 15px;
	font-size: 20px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
}

.buttonTypeMap{
	border: none;
	background-color: #cccccc;
	color: #666666;
	border-radius: 4px;
	text-decoration: none;
	padding: 5px 15px;
	font-size: 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
}