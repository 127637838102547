.logo{
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
}
.loginTextContainer h2{
    font-size: 34px;
    font-weight: 700;color:  #1A8B82;
}
.loginTextContainer p{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;color:  #1A8B82;
}
.title{
    display: flex;
    color: rgba(24, 24, 25, 0.9);
    font-weight: 500;
    font-size: 34px;
    align-items: center;
    justify-content: center;
}
.formInput{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.input{
    width: 100%;
    height: 45px;
    border-radius: 10px;
    border: 2px solid #DBDBDB;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
}
.input:hover{    
    border: 2px solid #00A69A;
}
.input:focus{
    outline: none;
    border: 2px solid #00A69A;
}
.icon{
    color:#888888;
    position: absolute;
    padding-left: 5px;
    padding-bottom: 7px;
}
.iconPassword{
    color:#888888;
    position: absolute;
    margin-left: -40px;
    margin-top: -16px;
    cursor: pointer;
}
.buttonLogin{
    width: 100%;
    height: 45px;
    background: #18827b;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
}
.buttonLogin:hover{
    background: #16706a;
    cursor: pointer;
}
.link{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #1A877F;
    text-decoration: none;
}
.link:hover{
    text-decoration: underline;
}
.reenviarCodText{
    cursor: pointer;
}