.ButtonExportChart {
	background: #1A877F;
	border: 2px solid #1A877F;
	border-radius: 10px;
	color: #FFFFFF;
	width: max-content;
	height: 35px;
	cursor: pointer;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 5px;
	text-decoration: none;
}

.ButtonExportChart:hover {
	cursor: pointer;
	background-color: #1a8780d5;
}

.ButtonExportChartDisabled {
	background: #ffffff;
	border: 2px solid #4d4d4d;
	border-radius: 10px;
	color: #000000;
	width: max-content;
	min-width: 200px;
	height: 35px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 5px;
	text-decoration: none;
}