.topContainer {
  display: flex;
  align-items: center;
  margin: 20px;
  font-size: 24px;
  font-weight: 500;
}
.temperature {
  font-size: 30px;
  font-weight: 500;
}
.imgTemperature {
  width: 10%;
}

.informationsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 15px;
}
.fontBold {
  font-weight: 600;
}
.imagemContainer {
  width: 35%;
  height: 35%;
}
