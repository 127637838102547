.labelButton {
	display: flex;
	align-items: center;
	padding: 8px;
	border: none;
	border-radius: 4px;
	color: white;
	background-color: #00a69a;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 14px;
	width: 150px;
}

.labelButton:hover {
	background-color: #038d84;
	cursor: pointer;
}

.registrationInputModal {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
	flex: 1;
	min-width: 45%;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	padding: 10px;
}

input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}

.modalContentError {
	color: red;
	margin-left: 30px;
}

.registrationButtonCancelar {
	background-color: #f94d4d;
	height: max-content;
	border-radius: 8px;
	border: 1px solid #e6e7e9;
	margin-right: 5px;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #ffffff;
	padding: 10px;
	min-width: 110px;
	width: 100%;
}

.registrationButtoncadastrar {
	background-color: #1a877f;
	color: white;
	height: max-content;
	border-radius: 8px;
	width: 100%;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 10px;
	min-width: 110px;
}

.headerRegisterAreaButton {
	font-size: 25px;
	text-decoration: none;
	margin-right: 10px;
	color: black;
	cursor: pointer;
	font-size: 25px;
	font-weight: 500;
}


.noErrControl {
	width: 100% !important;
	text-align: end;
	margin-right: 15px;
	display: flex;
}

.errControl {
	width: 100%;
	text-align: end;
	margin-right: 15px;
	display: flex;
	flex-direction: row;
	text-align: end;
}

.errControl select,
.errControl input {
	border: 1px solid red !important;
}

.errText {
	margin-bottom: -15px;
	color: red;
}

.noErrText {
	margin-bottom: -15px;
	color: rgba(255, 255, 255, 0);
	visibility: hidden;
}

.inputRequest {
	width: 100%;
}