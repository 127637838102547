.editButton {
	background: #4d9bac;
	border-radius: 4px;
	text-decoration: none;
	color: white;
	padding: 5px 15px;
	display: flex;
	align-items: center;
}

.deleteButton {
	background: #ff4f4f;
	border-radius: 4px;
	text-decoration: none;
	color: white;
	padding: 5px 15px;
	display: flex;
	align-items: center;
}

.genericButtonGreen {
	background: #00a69a;
	border-radius: 4px;
	text-decoration: none;
	color: white;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
}

.genericButtonGray {
	background: #e6e7e9;
	border-radius: 4px;
	text-decoration: none;
	color: black;
	padding: 5px 15px;
	display: flex;
	align-items: center;
}

.marginIconButton {
	margin-left: 5px;
}

.ButtonCancelarModal {
	background-color: rgb(255, 255, 255);
	width: 100%;
	height: 45px;
	border-radius: 8px;
	border: 1px solid #E6E7E9;
	font-family: "Poppins", sans-serif;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
	color: #565758;
	padding: 8px;
}

.ButtonCancelarModal:hover {
	background-color: #6b6d6d;
	color: white;
	cursor: pointer;
	transition: 0.3s;
}

.ButtonColherModal {
	background-color: #038d84;
	color: white;
	width: 100%;
	height: 45px;
	border-radius: 8px;
	border: none;
	cursor: pointer;
	font-family: "Poppins", sans-serif;
	font-weight: 500;
	font-size: 16px;
	padding: 8px;
}

.ButtonColherModal:hover {
	transition: 0.3s;
	background: #156661;
	cursor: pointer;
}