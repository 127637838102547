.selectInputPlaceholdertest {
  color: #000000 !important;
  height: 40px !important;
}
.Test {
  width: 100% !important;
  display: flex;
  height: max-content;
}
.containerQteEstoque {
  height: 45px;
  border-radius: 8px;
  width: auto;
  min-width: 100px;
  margin: 10px;
  color: #888888;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 150px;
  height: auto;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}

.ButtonCancelar {
  background-color: rgb(255, 255, 255);
  width: 250px;
  min-height: 40px;
  height: auto;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

  .ButtonColher {
  background-color: #038d84;
  color: white;
  width: max-content;
  min-height: 40px;
  height: auto;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 0 8px;
  margin-left: 10px;
}

.ButtonColher:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}

.titleTabelaModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.css-rorn0c-MuiTableContainer-root {
  max-height: 50vh !important;
}


.noErrControl {
  width: 100% !important;
  text-align: end;
  margin-right: 15px;
  display: flex;
}

.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}

.errControl {
  width: 100%;
  text-align: end;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  text-align: end;
}

.errControl select,
.errControl input {
  border: 1px solid red !important;
}

.errText {
  margin-bottom: -15px;
  color: red;
}

.inputRequestItem {
  width: 100%;
}

.registrationTitle {
  display: flex;
  color: #5b5b5d;
  font-size: 20px;
  padding: 10px 10px;
  font-weight: 500;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.addBtnModal {
  display: flex;
  background: #1a877f;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: none;
  margin-right: 50px;
}

.addBtnModal:hover {
  cursor: pointer;
}

.itemButtonCuture {
  background-color: #34a59c;
  color: white;
  font-weight: 700;
  border: none;
  padding: 2px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}

.itemNameCuture {
  display: flex;
  border-radius: 40px;
  background: #34a59c;
  font-family: "Poppins", sans-serif;
  color: white;
  font-size: 16px;
  padding: 5px 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
}

.registrationInputModal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
  flex: 1;
  min-width: 100%;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 10px;
}

.registrationButtonCancelar {
  background-color: rgb(255, 255, 255);
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  margin-right: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #565758;
  padding: 10px;
  min-width: 110px;
  width: 100%;
}

.registrationButtoncadastrar {
  background-color: #1a877f;
  color: white;
  height: 45px;
  border-radius: 8px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  min-width: 110px;
}