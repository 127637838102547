.tableGenerator, .tableGenerator th, .tableGenerator td {
    background: #f8f8fade;
    font-weight: bold;
    border-radius: 0;
}
.ActivitiesProductBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #00A69A;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    width: 50%;
    margin-left: auto;
}
.ActivitiesProductBtn:hover{
    background-color: #038d84;
    cursor: pointer;
}
.btn{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.ButtonCancelarModal{
    background-color: rgb(255, 255, 255);
    width: 50%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid #E6E7E9;    
    font-family: "Poppins", sans-serif;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #565758;
    padding: 8px;
}
.ButtonCancelarModal:hover{
    background-color: #6b6d6d;
    color:white;
    cursor: pointer;
    transition: 0.3s;
}
.ButtonColherModal{
    background-color: #038d84;
    color:white;
    width: 50%;
    height: 45px;
    border-radius: 8px;
    border:none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 8px;
}
.ButtonColherModal:hover{
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}