.selectInputPlaceholder {
  color: #888888;
}

.noErrControl {
  width: 100%;
  text-align: end;

  text-align: end;
  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: center;
  align-items: center;
}

.noErrText {
  margin-bottom: -15px;
  color: rgba(255, 255, 255, 0);
  visibility: hidden;
}

.errControl {
  width: 100%;
  text-align: end;

  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: center;
  align-items: center;
}

.errControl p {
  margin-left: 5px;
  margin-bottom: 10px;
  height: 100%;
  min-height: 45px;
  display: flex;
  flex-direction: row;
  text-align: end;
  justify-content: center;
  align-items: flex-start;
}

.errControl select,
.errControl input,
.errControl button {
  border: 1px solid red !important;
}

.errText {
  margin-bottom: -15px;
  color: red;
}

.ButtonCancelarModal {
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonCancelarModal:hover {
  background-color: #6b6d6d;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.ButtonColherModal {
  background-color: #038d84;
  color: white;
  width: 150px;
  height: 45px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColherModal:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}

.ButtonCancelar {
  background-color: rgb(255, 255, 255);
  width: 250px;
  min-height: 45px;
  height: max-content;
  border-radius: 8px;
  border: 1px solid #e6e7e9;
  font-family: "Poppins", sans-serif;
  margin-right: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #565758;
  padding: 8px;
}

.ButtonColher {
  background-color: #038d84;
  color: white;
  width: 250px;
  min-height: 45px;
  height: max-content;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  padding: 8px;
}

.ButtonColher:hover {
  transition: 0.3s;
  background: #156661;
  cursor: pointer;
}

.titleTabelaModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.css-rorn0c-MuiTableContainer-root {
  max-height: 50vh !important;
}
.inputLossesCad {
  width: 100%;
}
