.map {
  height: 100vh;
  width: 100%;
}
.popover {
  background: white;
  padding: 15px 10px;
  border: 1px solid;
  border-radius: 8px;
}
