.tableModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.trModal .thModal {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.thModal {
    flex: 1;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.trModal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tdModal {
    flex: 1;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid #E6E7E9;
    text-align: center;
    font-size: 18px;
}

.trModal:hover>.tdModal {
    background: rgba(0, 166, 154, 0.04);
    color: rgb(0, 0, 0);
    border: none;
}

.btn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}


.tableModal, .tableModal .thModal, .tableModal .tdModal, .tablePagination {
    background: #f8f8fade;
    font-weight: bold;
    border-radius: 0;
}

.labelButton {
    display: flex;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #00a69a;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    width: 150px;
    text-decoration: none;
}

.labelButton:hover {
    background-color: #038d84;
    cursor: pointer;
}

.registrationInputModal {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.registrationInputModal input,
.registrationInputModal select {
    flex: 1;
    min-width: 45%;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    padding: 10px;
}

input:focus {
    box-shadow: 0px 0px 5px 1px #00a69a;
    outline: 2px solid #00a69a;
}

.registrationButtonCancelar {
    background-color: rgb(255, 255, 255);
    height: 45px;
    border-radius: 8px;
    border: 1px solid #E6E7E9;
    margin-right: 5px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #565758;
    padding: 10px;
    min-width: 110px;
    width: 100%;
}

.registrationButtoncadastrar {
    background-color: #1A877F;
    color: white;
    height: 45px;
    border-radius: 8px;
    width: 100%;
    max-width: 150px;
    border: none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
}

.registrationTitle {
    display: flex;
    color: #5B5B5D;
    font-size: 20px;
    padding: 10px 10px;
    font-weight: 500;

}