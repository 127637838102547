.btnContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}

.btn {
  background-color: #00a69a;
  border: 2px solid #007d74;
  height: 45px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: 8px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s;
}
.btn:hover {
  background-color: #007d74;
}
