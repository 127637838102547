.tableGenerator, .tableGenerator th, .tableGenerator td {
    background: #f8f8fade;
    font-weight: bold;
    border-radius: 0;
}
.harvestButton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #00A69A;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.harvestButton:hover{
    background-color: #038d84;
    cursor: pointer;
}
.modalContent{
    color: #5B5B5D;
    font-size: 20px;
    font-weight: 500;
}
.registrationInput {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.registrationInput input,.registrationInput select {
	width: 100% !important;
    height: 35px;
	border-radius: 8px;
	border: 1px solid #dbdbdb;
	flex: 1;
	min-width: 45%;
}
input:focus {
	box-shadow: 0px 0px 5px 1px #00a69a;
	outline: 2px solid #00a69a;
}
.cultureNameModal{
    font-size: 16px;
    display: flex;
    margin-top: 25px;
}
.cultureNameModal p{
    margin-right: 15px;
}

.addBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.registrationButtonCancelar{
    background-color: rgb(255, 255, 255);
    height: 45px;
    border-radius: 8px;
    border: 1px solid #E6E7E9;
    margin-right: 5px;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #565758;
    padding: 10px; 
    min-width: 110px;
    width: 100%;
}
.registrationButtoncadastrar{
    background-color: #1A877F;
    color:white;
    height: 45px;
    border-radius: 8px;
    width: 100%;
    border:none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
    min-width: 110px;
}

.itemNameCuture{
    display: flex;
    border-radius: 40px;
    background: #34A59C;
    font-family: "Poppins", sans-serif;
    color: white;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: 500;
    align-items: center;
    justify-content: space-between  ;
}
.itemButtonCuture{
    background-color: #34A59C;
    color: white;
    font-weight: 700;
    border: none;
    padding: 2px;
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
}
.registrationTitle{
    display: flex;
    color: #5B5B5D;
    font-size: 20px;
    padding: 10px 10px;
    font-weight:  500;
  
}

.btn{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.ButtonCancelarModal{
    background-color: rgb(255, 255, 255);
    width: 50%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid #E6E7E9;    
    font-family: "Poppins", sans-serif;
    margin-right: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #565758;
    padding: 8px;
}
.ButtonCancelarModal:hover{
    background-color: #6b6d6d;
    color:white;
    cursor: pointer;
    transition: 0.3s;
}
.ButtonColherModal{
    background-color: #038d84;
    color:white;
    width: 50%;
    height: 45px;
    border-radius: 8px;
    border:none;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 8px;
}
.ButtonColherModal:hover{
    transition: 0.3s;
    background: #156661;
    cursor: pointer;
}